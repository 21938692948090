import './Header.scss';
import headerLogo from '../../images/logoHeader.png'

export default function Header () {
    return (
        <div id={'header'}>
            <img src={headerLogo} alt={'Logo'} />
            <div className="separator"> </div>
        </div>
    );
}
