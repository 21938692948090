import './Affiliate.scss';
import ReactPlayer from "react-player";

export default function Affiliate() {
    return (
        <div className="page affiliate">
            <div className={'inner'}>
                <h1>A Technical Breakthrough<br /> in Vitamin D Optimatization for<br /> a Longer, Healthier Life.</h1>
                <div id={'playerWrapper'}>
                    <ReactPlayer controls={true} url="https://vimeo.com/623031035" width='100%' height='100%' responsive={true} />
                </div>
                <h2>If you are interested in booking Dr Millman for your podcast or summit or<br /> having a conversation about how you can collaborate - contact her at<br /> <a href="mailto:drkimprivate@gmail.com">drkimprivate@gmail.com</a> or <a href="tel:+14082093735">408.209.3735</a></h2>
            </div>
        </div>
    );
}
