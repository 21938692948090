import {
    BrowserRouter as Router,
    Switch,
    Route,
} from "react-router-dom";
import Affiliate from "./Pages/Affiliate/Affiliate";
import ComingSoon from "./Pages/ComingSoon/ComingSoon";
import Header from "./Pages/Header/Header";
import Footer from "./Pages/Footer/Footer";
import './App.scss';

export default function App() {
    return (
        <Router>
            <Header />
                <Switch>
                    <Route path="/affiliate">
                        <Affiliate />
                    </Route>
                    <Route path="/">
                        <ComingSoon />
                    </Route>
                </Switch>
            <Footer />
        </Router>
    );
}
