import './Footer.scss';
import dna from '../../images/dna.png';
import dnaSocial from '../../images/social_dna.png';
import footerLogo from '../../images/logo_footer_white.png';
import {NavLink} from "react-router-dom";

export default function Footer () {
    return (
        <footer>
            <img className="column dna" src={dna} alt={'dna'} />
            <div className="column menu">
                <div className="column">
                    <img className="header" alt="More than Your GENES" src={footerLogo} />
                    <div>
                        <ul>
                            <li><NavLink to="/" exact={true}>Home</NavLink></li>
                            <li><NavLink to="/coming_soon">Products</NavLink></li>
                            <li><NavLink to="/coming_soon">Resources</NavLink></li>
                        </ul>
                        <ul>
                            <li><NavLink to="/coming_soon">Privacy</NavLink></li>
                            <li><NavLink to="/coming_soon">About Us</NavLink></li>
                            <li><NavLink to="/coming_soon">Terms & Conditions</NavLink></li>
                            <li><NavLink to="/coming_soon">Medical Disclamer</NavLink></li>
                            <li><NavLink to="/coming_soon">Contact Us</NavLink></li>
                        </ul>
                    </div>
                </div>
                <div className="column">
                    <div className="social">

                    </div>
                    <img className="social_dna" src={dnaSocial} alt={'dnaSocial'} />
                </div>
                <p><span className="reserved">®</span> More Than Your Genes. All Rights Reserved.  <a href="mailto:info@morethanyourgenes.com">info@VitaminDOptimizer.com</a></p>
            </div>
        </footer>
    );
}
